<template>
    <content-wrapper>
        <w-data-table
            :headers="headers"
            :filter-by="[ 'title', 'level', 'description' ]"
            searchable
            :draggable="isDraggable"
            itemKey="uuid"
            :items="groups"
            @dragged="onDragged"
        >
            <template #filters>
                <v-select
                    v-model="filterByLevel"
                    :placeholder="$trans('Level')"
                    dense
                    clearable
                    hide-details
                    single-line
                    :items="levels"
                    item-text="title"
                    item-value="uuid"
                />
            </template>
            <template #actions>
                <w-btn-action
                    :text="$trans('Add group')"
                    icon="ADD"
                    @click="onShowForm"
                />
                <w-btn-update
                    :disabled="!draggedData"
                    @click="onDraggedUpdate"
                />
            </template>
            <template #item.title="{ item }">
                <div class="d-flex py-2">
                    <div>
                        <div class="font-weight-bold">{{ item.title }}</div>
                        <small class="caption d-none d-md-block">
                            {{ item.description }}
                        </small>
                    </div>
                </div>
            </template>
            <template #item.actions="{ item }">
                <div class="d-flex justify-end">
                    <v-btn
                        icon
                        color="success"
                        @click="onShowForm(item)"
                    >
                        <w-icon size="20" value="mdi-pencil"/>
                    </v-btn>
                    <v-btn
                        icon
                        color="error"
                        @click="onDelete({}, item)"
                    >
                        <w-icon size="20" value="mdi-delete"/>
                    </v-btn>
                </div>
            </template>
        </w-data-table>
        <w-aside-drawer
            v-model="isDrawer"
            :title="$trans('Group')"
            :loading="loading"
        >
            <div class="pa-4">
                <w-simple-form
                    :key="'groupForm' + formKey"
                    :fields="formFields"
                    :item="item"
                    :errors="errors"
                    deletable
                    :disabled="loading"
                    @save="onStore"
                    @update="onUpdate"
                    @delete="onDelete"
                />
            </div>
        </w-aside-drawer>
    </content-wrapper>
</template>

<script>
import _ from 'lodash'
import ContentWrapper from '@/components/ContentWrapper'
import { deleteGroup, resortGroups, storeGroup, updateGroup } from '@apps/school/api/groups'
import ConfirmationSets from '@/utils/ConfirmationSets'
import { max, required } from '@/utils/validation'

export default {
    name: 'GroupsManage',
    components: { ContentWrapper },
    computed: {
        levels() {
            return this.$store.getters['school/levels']
        },
        groups() {
            let groups = []

            this.levels.forEach(level => {
                const oGroups = level.groups
                oGroups.map(group => {
                    group.level = level.title
                    return group
                })

                groups = groups.concat(level.groups)
            })

            if (this.filterByLevel) {
                return _.filter(groups, [ 'level_uuid', this.filterByLevel ])
            } else {
                return groups
            }
        },
        headers() {
            return [
                {
                    text: this.$trans('Title'),
                    value: 'title'
                },
                {
                    text: this.$trans('Level'),
                    value: 'level'
                },
                {
                    align: 'right',
                    value: 'actions'
                }
            ]
        },
        formFields() {
            return [
                {
                    name: 'title',
                    type: 'v-text-field',
                    props: {
                        label: this.$trans('Title'),
                        outlined: true,
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'level_uuid',
                    type: 'v-select',
                    props: {
                        label: this.$trans('Level'),
                        outlined: true,
                        dense: true,
                        items: this.levels,
                        itemText: 'title',
                        itemValue: 'uuid',
                        rules: [
                            required
                        ]
                    }
                },
                {
                    name: 'description',
                    type: 'v-textarea',
                    props: {
                        label: this.$trans('Summary'),
                        outlined: true,
                        dense: true,
                        counter: 100,
                        rules: [
                            required,
                            v => max(v, 100)
                        ]
                    }
                }
            ]
        },
        isDraggable() {
            return this.filterByLevel !== null
        }
    },
    watch: {
        filterByLevel() {
            this.draggedData = null
        }
    },
    data() {
        return {
            loading: false,
            errors: {},
            isDrawer: false,
            formKey: 0,
            item: {},
            filterByLevel: null,
            draggedData: null
        }
    },
    methods: {
        onShowForm(item) {
            this.item = item || {}
            this.formKey++
            this.isDrawer = true
        },
        async fetchLevels() {
            await this.$store.dispatch('school/fetchLevels')
        },
        async onStore(data) {
            this.loading = true
            try {
                await storeGroup(data)
                await this.fetchLevels()
                this.isDrawer = false
                this.loading = false
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.loading = false
            }
        },
        async onUpdate(data, item) {
            this.loading = true
            try {
                await updateGroup(item.uuid, data)
                await this.fetchLevels()
                this.isDrawer = false
                this.loading = false
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.loading = false
            }
        },
        async onDelete(data, item) {
            this.isDrawer = false

            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${this.$trans('Delete')} ${item.title}`
            }))

            if (!isConfirm) {
                return
            }

            this.$lprogress.begin()

            try {
                await deleteGroup(item.uuid)
                await this.fetchLevels()
                this.$lprogress.end()
            } catch (error) {
                this.errors = error.response.data.errors || {}
                this.$lprogress.end()
            }
        },
        onDragged(data) {
            this.draggedData = null
            this.draggedData = data
        },
        async onDraggedUpdate() {
            this.$lprogress.begin()

            try {
                await resortGroups({ data: this.draggedData })
                await this.fetchLevels()
                this.$lprogress.end()
                this.draggedData = null
            } catch (error) {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
